<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{$t('company.company_profile')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
                    <!--          <router-link to="/dashboard" class="btn btn-light font-weight-bolder"><v-icon>mdi-chevron-left</v-icon> Back</router-link>-->
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-1 variant="link" class="text-left font-weight-bold text-dark">{{ $t('company.basic_information') }}</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="form-group row">
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.business_name')}}<span class="text-danger">*</span></label>
                                    <input type="text" v-model="data.business_name" class="form-control" :class="validation && validation.business_name ? 'is-invalid' : ''" :placeholder="$t('company.business_name')"/>
                                    <span v-if="validation && validation.business_name" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.business_name[0] }}
                                    </span>
                                </div>

                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.business_type')}}</label>
                                    <select name="" id="activity_type" v-model="data.activity_type_id" class="form-control">
                                        <option v-for="row in activity_type_list" :value="row.id" :key="row.id">
                                            {{ row.name }}
                                        </option>
                                    </select>
                                    <span v-if="validation && validation.activity_type_id" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.activity_type_id[0] }}
                                    </span>
                                </div>

                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.first_name')}}</label>
                                    <input type="text" class="form-control" :class=" validation && validation.first_name ? 'is-invalid' : ''" v-model="data.first_name" :placeholder="$t('users.first_name')"/>
                                    <span v-if="validation && validation.first_name" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.first_name[0] }}
                                    </span>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.last_name')}}</label>
                                    <input type="text" class="form-control" :class=" validation && validation.last_name ? 'is-invalid' : ''" v-model="data.last_name" :placeholder="$t('users.last_name')"/>
                                    <span v-if="validation && validation.last_name" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.last_name[0] }}
                                    </span>
                                </div>

                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.currency')}}</label>
                                    <select name="" id="currency" v-model="data.currency_id" class="form-control">
                                        <option v-for="row in currency_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                                    </select>
                                    <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.currency_id[0] }}
                                    </span>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.language')}}</label>
                                    <select name="" id="language" v-model="data.language_id" class="form-control">
                                        <option v-for="row in language_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                                    </select>
                                    <span v-if="validation && validation.language_id" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.language_id[0] }}
                                    </span>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.time_zone')}}</label>
                                    <select name="" id="timezone_id" v-model="data.timezone_id" class="form-control">
                                        <option v-for="row in time_zone_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                                    </select>
                                    <span v-if="validation && validation.timezone_id" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.timezone_id[0] }}
                                    </span>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.date_format')}}</label>
                                    <select name="" id="date_format_id" v-model="data.date_format_id" class="form-control">
                                        <option v-for="row in date_format_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                                    </select>
                                    <span v-if="validation && validation.date_format_id" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.date_format_id[0] }}
                                    </span>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.printing_method')}}</label>
                                    <select name="" id="printing_method" v-model="data.printing_method" class="form-control">
                                        <option v-for="row in printing_method_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                                    </select>
                                    <span v-if="validation && validation.printing_method" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.printing_method[0] }}
                                    </span>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.upload_logo')}}</label>
                                    <div class="form-group">
                                      <custom-upload-image-2 :upload="dir_upload" :start-link="'base'" :image-url="logo_url" @file="listenerAttachment"></custom-upload-image-2>

                                      <!--                                        <upload-image-->
<!--                                                @file="listenerAttachment"-->
<!--                                                :inner-id="'logo'"-->
<!--                                                :placeholder="$t('company.upload_logo')"-->
<!--                                                :upload="dir_upload"-->
<!--                                                :start-link="'base'"-->
<!--                                                v-bind:valueprop="{name:'logo',path:logo_url}" v-if="reloadUploadAttachment">-->
<!--                                        </upload-image>-->
                                    </div>
                                </div>
                                <div class="col-lg-6  mb-5">
                                    <label>{{$t('activity_logs')}}</label>
                                    <multiselect
                                        v-model="data.log_keys"
                                        :placeholder="$t('activity_logs')"
                                        label="key"
                                        track-by="id"
                                        :options="activity_logs"
                                        :multiple="true"
                                        :taggable="true"
                                        >
                                        <!-- :searchable="true" -->
                                    </multiselect>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark">{{ $t('company.address_information') }}</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="form-group row">
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.country')}}</label>
                                    <div class="input-group">
                                        <multiselect
                                            v-model="country"
                                            :placeholder="$t('company.country')"
                                            label="name"
                                            track-by="id"
                                            :options="countries"
                                            :multiple="false"
                                            :taggable="false"
                                            :show-labels="false"
                                            :show-no-options="false"
                                            :show-no-results="false"
                                            @search-change="getCountries($event)"
                                            :internal-search="false">
                                        </multiselect>
                                        <span v-if="validation && validation.country_id" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.country_id[0] }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('users.cities')}}</label>
                                    <div class="input-group">
                                        <multiselect
                                            :class="validation && validation.city ? 'is-invalid' : ''"
                                            v-model="city"
                                            :placeholder="$t('users.cities')"
                                            label="name"
                                            track-by="id"
                                            :options="cities"
                                            :multiple="false"
                                            :taggable="false"
                                            :show-labels="false"
                                            :show-no-options="false"
                                            :show-no-results="false">
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.postal_code')}}</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" :class="validation && validation.postal_code ? 'is-invalid' : ''" v-model="data.postal_code" :placeholder="$t('company.postal_code')"/>
                                        <span v-if="validation && validation.postal_code" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.postal_code[0] }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.district')}}</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" :class="validation && validation.district ? 'is-invalid' : ''" v-model="data.district" :placeholder="$t('company.district')"/>
                                        <span v-if="validation && validation.district" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.district[0] }}
                                          </span>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.address_1')}}</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" :class="validation && validation.address_1 ? 'is-invalid' : ''" v-model="data.address_1" :placeholder="$t('company.address_1')"/>
                                        <span v-if="validation && validation.address_1" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.address_1[0] }}
                                          </span>
                                    </div>
                                </div>
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.address_2')}}</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" :class="validation && validation.address_2 ? 'is-invalid' : ''" v-model="data.address_2" :placeholder="$t('company.address_2')"/>
                                        <span v-if="validation && validation.address_2" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.address_2[0] }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-3 variant="link" class="text-left font-weight-bold text-dark">{{ $t('company.contact_information') }}</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="form-group row">
                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.mobile')}}</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" :class="validation && validation.mobile ? 'is-invalid' : ''" v-model="data.mobile" :placeholder="$t('company.mobile')"/>
                                        <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">{{ validation.mobile[0] }}</span>
                                    </div>
                                </div>

                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.phone')}}</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" :class="validation && validation.telephone ? 'is-invalid' : ''" v-model="data.telephone" :placeholder="$t('company.phone')"/>
                                        <span v-if="validation && validation.telephone" class="fv-plugins-message-container invalid-feedback">{{ validation.telephone[0] }}</span>
                                    </div>
                                </div>

                                <div class="col-lg-6 mb-5">
                                    <label>{{$t('company.email')}}</label>
                                    <div class="input-group">
                                        <input type="email" class="form-control" :class="validation && validation.email ? 'is-invalid' : ''" v-model="data.email" :placeholder="$t('company.email')"/>
                                        <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">{{ validation.email[0] }}</span>
                                    </div>
                                </div>

                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>


            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-lg-6">
                        <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                    </div>
                </div>
            </div>

        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "form-user",
        data() {
            return {
                mainRoute: 'settings/company',
                mainRouteDependency: 'base/dependency',
                dir_upload: 'companies',
                date_format_list: [{id: 1, name: 'Day'}, {id: 2, name: 'Month'}, {id: 3, name: 'Year'}],
                printing_method_list: [{id: 0, name: 'browse'}, {id: 1, name: 'pdf'}],
                activity_type_list: [{id: 0, name: 'Services & Products'}, {id: 1, name: 'Products Only'}, {id: 2, name: 'Services Only'}],
                currency_list: [],
                language_list: [],
                time_zone_list: [],
                countries: [],
                cities: [],
                activity_logs : [],
                country: null,
                city: null,
                data: {
                    business_name: null,
                    activity_type_id: null,
                    first_name: null,
                    last_name: null,
                    currency_id: null,
                    language_id: null,
                    timezone_id: null,
                    date_format_id: null,
                    printing_method: null,
                    country_id: null,
                    city_id: null,
                    postal_code: null,
                    district: null,
                    address_1: null,
                    address_2: null,
                    mobile: null,
                    telephone: null,
                    email: null,
                    logo: null,
                    log_keys : [],
                },
                logo_url: null,
                reloadUploadAttachment: true,
                isEditing: false,
                validation: null,
            };
        },
        watch: {
            "country": function (val) {
                if (val) {
                    this.data.country_id = val.id;
                    this.getCities(val.code2);
                }
            },
            "city": function (val) {
                if (val) {
                    this.data.city_id = val.id;
                }
            },
        },
        methods: {
            save() {
                // if (this.isEditing) {
                this.update();
                // } else {
                //   this.create();
                // }
            },

            // create() {
            //     ApiService.post(this.mainRoute, {
            //         ...this.data,
            //     }).then((response) => {
            //         this.validation = null;
            //         this.$successAlert(response.data.message);
            //     }).catch((error) => {
            //         this.validation = error.response ? error.response.data.errors : null;
            //         console.log(this.validation);
            //     });
            // },

            update() {
                ApiService.post(this.mainRoute + '/setCompany', {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            getData() {
                this.reloadUploadAttachment = false;
                ApiService.get(this.mainRoute + '/getCompany').then((response) => {
                    this.isEditing = true;
                    this.data = response.data.data;
                    this.logo_url = response.data.data.logo_url;
                    this.reloadUploadAttachment = true;
                });
            },

            getActivityLogList() {
                ApiService.get(`${this.mainRouteDependency}/activity_log_list`).then((response) => {
                    this.activity_logs = response.data.data;
                });
            },

            // getCountries() {
            //     ApiService.get(this.mainRouteDependency + "/countries").then((response) => {
            //         this.countries = response.data.data;
            //     });
            // },

            getCountries(filter) {
                if (filter && filter.length >= 3) {
                    ApiService.get(this.mainRouteDependency+"/countries", {params: {filter: filter}}).then((response) => {
                    this.countries = response.data.data;
                    });
                }
            },

            getCities(id) {
                ApiService.get(this.mainRouteDependency + `/cities/${id}`).then((response) => {
                    this.cities = response.data.data;
                });
            },

            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currency_list = response.data.data;
                });
            },
            getLanguages() {
                ApiService.get(this.mainRouteDependency + "/languages").then((response) => {
                    this.language_list = response.data.data;
                });
            },
            getTimezone() {
                ApiService.get(this.mainRouteDependency + "/timezone").then((response) => {
                    this.time_zone_list = response.data.data;
                });
            },
            listenerAttachment(event) {
                if (event) {
                    this.logo_url = event.pathDB;
                    this.data.logo = event.name;
                }
            },

        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.company_profile")}]);
            // this.getCountries();
            this.getCurrencies();
            this.getLanguages();
            this.getTimezone();
            this.getData();
            this.getActivityLogList();
        },
    };
</script>


